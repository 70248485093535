import React, { useState, useEffect } from "react";
import { scrollToSection } from "../../utils";

const InspectionVerticalMenu = () => {
  const [activeSection, setActiveSection] = useState(null);

  useEffect(() => {
    const handleScroll = () => {
      const sections = document.querySelectorAll(".section");

      sections.forEach((section) => {
        // console.log(section)
        const rect = section.getBoundingClientRect();
        console.log(section.id, rect.top, rect.bottom, window.innerHeight);

        if (rect.top >= 0 && rect.bottom <= window.innerHeight) {
          setActiveSection(section.id);
        }
      });
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  if (
    !activeSection ||
    activeSection === "footer section5" ||
    activeSection === "inspection-benefits" ||
    activeSection === "how-reporting-works"
  ) {
    return null; // Hide the menu when there's no active section or after section4
  }

  return (
    <div className="fixed top-1/2 right-4 transform -translate-y-1/2 2xl:right-96">
      <span className="text-black font-bold">FEATURES</span>
      <ul className="list-none mt-2">
        <li
          className={`mb-3 ${
            activeSection === "inspection-reports"
              ? "text-white bg-black"
              : "text-gray-700"
          }`}
        >
          {/* <a href="#section1">Daily Reports</a> */}
          <button onClick={() => scrollToSection("inspection-reports")}>
            Inspection Reports
          </button>
        </li>
        <li
          className={`mb-3 ${
            activeSection === "multiformat"
              ? "text-white bg-black"
              : "text-gray-700"
          }`}
        >
          {/* <a href="#section2">Schedule Management</a> */}
          <button onClick={() => scrollToSection("multiformat")}>
            Multiple Formats
          </button>
        </li>
        <li
          className={`mb-3 ${
            activeSection === "multilingual-inspection"
              ? "text-white bg-black"
              : "text-gray-700"
          }`}
        >
          {/* <a href="#section3">Multilingual</a> */}
          <button onClick={() => scrollToSection("multilingual-inspection")}>
            Multilingual
          </button>
        </li>
        {/* <li
          className={`mb-3 ${
            activeSection === "section4"
              ? "text-amber-400 font-bold"
              : "text-gray-700"
          }`}
        >
          <a href="#section3">Project Information Search</a>
        </li> */}
      </ul>
    </div>
  );
};

export default InspectionVerticalMenu;
