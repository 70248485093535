import React, { useEffect, useState } from "react";
import logo from "../../img/logo.svg";
import { Link } from "react-router-dom";
import { MdOutlineMenu } from "react-icons/md";

const InspectionHeader = ({ scroll, width }) => {
  const [scrollPosition, setScrollPosition] = useState(0);
  const [backgroundBlack, setBackgroundBlack] = useState(false);
  // const [width, setWidth] = useState(window.innerWidth);
  const [openMenu, setOpenMenu] = useState(false);
  const [openFeatures, setOpenFeatures] = useState(false);

  useEffect(() => {
    const background = document.getElementsByClassName("main");
    for (let c of background[0].classList) {
      if (c === "bg-black") {
        setBackgroundBlack(true);
      }
    }
  }, []);

  useEffect(() => {
    const updatePosition = () => {
      setScrollPosition(window.scrollY);
    };

    window.addEventListener("scroll", updatePosition);

    updatePosition();

    return () => window.removeEventListener("scroll", updatePosition);
  }, []);

  // useEffect(() => {
  //   const handleResize = () => {
  //     setWidth(window.innerWidth);
  //   };

  //   window.addEventListener("resize", handleResize);

  //   return () => {
  //     window.removeEventListener("resize", handleResize);
  //   };
  // }, []);

  const handleMenu = () => {
    setOpenMenu(!openMenu);
  };

  const menuClick = (section) => {
    scroll(section);
    if (openFeatures) {
      // Only close the dropdown if it's open
      setOpenFeatures(false);
    }
  };

  return (
    <>
      {width > 660 ? (
        <div
          id="nav-bar"
          className={`py-5 px-20  z-40 fixed w-full 2xl:px-96 flex flex-row justify-between ${
            scrollPosition < 60 ? "" : "bg-black"
            // scrollPosition < 60 ? "" : "bg-[#000000E5]"
          }`}
        >
          <Link to="/inspection">
            <img
              src={logo}
              alt="logo"
              className={`w-[50%] ${
                scrollPosition < 60 ? "w-[20%]" : "w-[12%]"
              }`}
            />
          </Link>
          <div className="flex flex-row items-center">
            <button
              className={`font-bold hover:underline mr-12 ${
                backgroundBlack || scrollPosition > 60
                  ? "text-white"
                  : "text-black"
              }`}
              // className="text-black text-xl font-bold bg-white px-3 py-2 border-2 border-black shadow-md"

              onClick={() => menuClick("inspection-benefits")}
            >
              Benefits
            </button>
            <div className="relative inline-block text-left">
              <button
                onClick={() => setOpenFeatures(!openFeatures)}
                className={`font-bold hover:underline mr-12 ${
                  backgroundBlack || scrollPosition > 60
                    ? "text-white"
                    : "text-black"
                }`}
              >
                Features
              </button>

              {openFeatures && (
                <div className="origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5">
                  <div
                    className="py-1"
                    role="menu"
                    aria-orientation="vertical"
                    aria-labelledby="options-menu"
                  >
                    <div
                      className="block px-4 py-2 text-sm text-gray-700 hover:bg-black hover:text-white hover:cursor-pointer"
                      role="menuitem"
                      onClick={() => menuClick("inspection-reports")}
                    >
                      Inspection Reports
                    </div>
                    <div
                      className="block px-4 py-2 text-sm text-gray-700 hover:bg-black hover:text-white hover:cursor-pointer"
                      role="menuitem"
                      onClick={() => menuClick("multiformat")}
                    >
                      Multi-format Reports
                    </div>
                    <div
                      className="block px-4 py-2 text-sm text-gray-700 hover:bg-black hover:text-white hover:cursor-pointer"
                      role="menuitem"
                      onClick={() => menuClick("multilingual-inspection")}
                    >
                      Multilingual
                    </div>
                  </div>
                </div>
              )}
            </div>
            <button
              className={`font-bold hover:underline mr-12 ${
                backgroundBlack || scrollPosition > 60
                  ? "text-white"
                  : "text-black"
              }`}
              // className="text-black text-xl font-bold bg-white px-3 py-2 border-2 border-black shadow-md"

              onClick={() => menuClick("how-reporting-works")}
            >
              How it Works
            </button>
            {/* <Link to="/blog" className="">
              <div className="w-[60px]  flex mr-6">
                <span
                  className={`font-bold hover:underline ${
                    backgroundBlack || scrollPosition > 60
                      ? "text-white"
                      : "text-black"
                  }`}
                >
                  Blog
                </span>
              </div>
            </Link> */}

            <div className="w-[60px]  flex mr-6">
              <span
                className={`font-bold hover:underline ${
                  backgroundBlack || scrollPosition > 60
                    ? "text-white"
                    : "text-black"
                }`}
              >
                <a
                  href="https://app.flowlly.com/"
                  target="_blank"
                  rel="noreferrer"
                >
                  Login
                </a>
              </span>
            </div>

            <button
              className={`text-black text-xl font-bold px-3 py-2 border-2 border-black  hover:bg-amber-400 bg-white ${
                scrollPosition < 60 ? "shadow-[5px_5px_0px_0px_#1a202c]" : ""
              }`}
              // className="text-black text-xl font-bold bg-white px-3 py-2 border-2 border-black shadow-md"

              onClick={() => scroll("footer")}
            >
              Try It Now
            </button>
          </div>
        </div>
      ) : (
        <div className="py-4 px-4 fixed flex flex-row justify-between z-40">
          <Link to="/">
            <img src={logo} alt="logo" className="w-[35%]" />
          </Link>
          <div
            className={`border rounded-md ${
              backgroundBlack
                ? "border-white bg-black "
                : "border-gray-500 bg-white"
            }`}
            onClick={() => handleMenu()}
          >
            <MdOutlineMenu className="text-2xl" />
          </div>
          {openMenu && (
            <div className="noselect flex flex-col text-black text-lg border border-gray-100 absolute right-4 top-12 p-4 bg-white shadow-lg rounded">
              <buton className="mb-2" onClick={() => scroll("benefits-mobile")}>
                Benefits
              </buton>
              <buton className="mb-2" onClick={() => scroll("features-mobile")}>
                Features
              </buton>
              <buton
                className="mb-2"
                onClick={() => scroll("how-it-works-mobile")}
              >
                How it Works
              </buton>
              {/* <Link to="/blog" className="mb-2">
                <span>Blog</span>
              </Link> */}

              <span className="font-bold" onClick={() => scroll("footer")}>
                Try It Now
              </span>
            </div>
          )}
          {/* <div
          className="bg-white text-black py-1 px-2 rounded-full shadow-lg"
          onClick={scroll}
        >
          Contact Us
        </div> */}
        </div>
      )}
    </>
  );
};

export default InspectionHeader;
