import { React, useState, useEffect } from "react";

import Footer from "./components/Footer";

import { scrollToSection } from "./utils";
import QAQCContent from "./components/QAQC-site/QAQCContent";
import QAQCMobile from "./components/QAQC-site/QAQCMobile";
import QAQCForm from "./components/QAQC-site/QAQCForm";
import QAQCHeader from "./components/QAQC-site/QAQCHeader";

const QAQC = () => {
  const [width, setWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <>
      {width < 660 ? (
        <div>
          <QAQCMobile scroll={scrollToSection} width={width} />
          <Footer width={width} />
        </div>
      ) : (
        <div className="main bg-white w-full h-full  flex justify-center">
          <div className="2xl:max-w-[1440px] flex justify-center ">
            {/* <img
              src={hero_image}
              alt="hero"
              className="z-0 absolute top-0 right-0 2xl:right-[400px] w-[50%] 2xl:w-[30%]"
            /> */}
            <QAQCHeader scroll={scrollToSection} width={width} />
            <div>
              <div>
                <QAQCContent />
              </div>
              <div id="footer section5" className="bg-white section">
                <QAQCForm width={width} />
                <Footer width={width} />
                <div></div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default QAQC;
