import { useState, useEffect } from "react";
import { createBlog, uploadFile, fetchAuthors } from "../data/blogs";

function BlogContentManager() {
  const [blogData, setBlogData] = useState({
    title: "",
    cover_image: "",
    description: "",
    author: { name: "", photo: "" },
    topic: "",
    intro: "",
    sections: [{ name: "", type: "text", content: "", index: 0 }],
  });
  const [authors, setAuthors] = useState([]);

  useEffect(() => {
    const getAuthors = async () => {
      try {
        const data = await fetchAuthors();
        setAuthors(data);
      } catch (error) {
        console.error("Error fetching authors:", error);
      }
    };

    getAuthors();
  }, []); // Empty dependency array ensures this runs once

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setBlogData({ ...blogData, [name]: value });
  };

  const handleSectionChange = (index, field, value) => {
    const updatedSections = [...blogData.sections];
    updatedSections[index][field] = value;

    // If type changes to video or image, clear the content
    if (field === "type" && (value === "video" || value === "image")) {
      updatedSections[index].content = "";
    }

    setBlogData({ ...blogData, sections: updatedSections });
  };

  const addSection = () => {
    setBlogData({
      ...blogData,
      sections: [
        ...blogData.sections,
        {
          name: "",
          type: "text",
          content: "",
          index: blogData.sections.length,
        },
      ],
    });
  };

  const removeSection = (index) => {
    const updatedSections = [...blogData.sections];
    updatedSections.splice(index, 1);
    setBlogData({ ...blogData, sections: updatedSections });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      // 1. Upload the cover image first and get the URL
      let coverImageUrl = blogData.cover_image; // Keep existing URL if no new file
      if (e.target.imageUpload.files[0]) {
        coverImageUrl = await uploadFile(e.target.imageUpload.files[0]);
        console.log("Cover image uploaded successfully!", coverImageUrl);
      }

      // 2. Process image uploads for sections
      const updatedSections = await Promise.all(
        blogData.sections.map(async (section) => {
          if (
            section.type === "image" &&
            e.target[`sectionUpload-${section.index}`].files[0]
          ) {
            try {
              const imageUrl = await uploadFile(
                e.target[`sectionUpload-${section.index}`].files[0]
              );
              return { ...section, content: imageUrl };
            } catch (error) {
              console.error("Error uploading section image:", error);
              // Handle the error appropriately
            }
          }
          return section;
        })
      );

      // 3. Update the blogData with the processed sections and cover image URL
      const updatedBlogData = {
        ...blogData,
        cover_image: coverImageUrl, // Update with new or existing URL
        sections: updatedSections,
      };

      // 4. Save the updated blog data to Supabase
      await createBlog(updatedBlogData);
      console.log("Blog data saved successfully!", updatedBlogData);

      // 5. Optionally, you can reset the form or show a success message
      setBlogData({
        title: "",
        cover_image: "",
        description: "",
        author: "",
        topic: "",
        intro: "",
        sections: [{ name: "", type: "text", content: "", index: 0 }],
      });
    } catch (error) {
      console.error("Error uploading cover image or saving blog data:", error);
      // Handle the error appropriately (e.g., show an error message)
    }
  };

  const handleImageSubmit = async (e) => {
    e.preventDefault();
    const file = e.target.imageUpload.files[0];

    if (file) {
      try {
        const imageUrl = await uploadFile(file); // Assuming you have an uploadFile function
        console.log("Image uploaded successfully!", imageUrl);
        // Now, you can use the imageUrl as needed (e.g., display it, send it to a server)
      } catch (error) {
        console.error("Error uploading image:", error);
        // Handle the error appropriately (e.g., show an error message to the user)
      }
    }
  };

  return (
    <>
      <form onSubmit={handleSubmit} className="p-4">
        <div className="mb-4">
          <label
            htmlFor="title"
            className="block text-sm font-medium text-gray-700"
          >
            Title
          </label>
          <input
            type="text"
            id="title"
            name="title"
            value={blogData.title}
            onChange={handleInputChange}
            className="mt-1 p-2 border border-gray-300 rounded-md w-full"
          />
        </div>
        {/* <div className="mb-4">
          <label
            htmlFor="imageUpload"
            className="block text-sm font-medium text-gray-700"
          >
            Blog Cover Image
          </label>
          <input
            type="file"
            id="imageUpload"
            accept="image/*" // Restrict to image files
            onChange={handleCoverUpload}
            className="mt-1"
          />
        </div> */}
        <div className="mb-4">
          <label
            htmlFor="imageUpload"
            className="block text-sm font-medium text-gray-700"
          >
            Blog Cover Image
          </label>
          <input
            type="file"
            id="imageUpload"
            accept="image/*"
            // No onChange handler here!
            className="mt-1"
          />
        </div>

        {/* <div className="mb-4">
          <label
            htmlFor="author"
            className="block text-sm font-medium text-gray-700"
          >
            Author
          </label>
          <input
            type="text"
            id="author"
            name="author"
            value={blogData.author}
            onChange={handleInputChange}
            className="mt-1 p-2 border border-gray-300 rounded-md w-full"
          />
        </div> */}
        <div className="mb-4">
          <label
            htmlFor="author"
            className="block text-sm font-medium text-gray-700"
          >
            Author
          </label>
          <select
            id="author"
            name="author"
            value={blogData.author}
            onChange={handleInputChange}
            className="mt-1 p-2 border border-gray-300 rounded-md w-full"
          >
            <option value="">Select an author</option>
            {authors.length > 0 ? (
              authors.map((author) => (
                <option key={author.id} value={author.name}>
                  {author.name}
                </option>
              ))
            ) : (
              <option value="" disabled>
                Loading authors...
              </option>
            )}
          </select>
        </div>

        <div className="mb-4">
          <label
            htmlFor="topic"
            className="block text-sm font-medium text-gray-700"
          >
            Topic
          </label>
          <input
            type="text"
            id="topic"
            name="topic"
            value={blogData.topic}
            onChange={handleInputChange}
            className="mt-1 p-2 border border-gray-300 rounded-md w-full"
          />
        </div>

        <div className="mb-4">
          <label
            htmlFor="description"
            className="block text-sm font-medium text-gray-700"
          >
            Description
          </label>
          <textarea
            id="description"
            name="description"
            value={blogData.description}
            onChange={handleInputChange}
            className="mt-1 p-2 border border-gray-300 rounded-md w-full"
            rows={3}
          />
        </div>

        <div className="mb-4">
          <label
            htmlFor="intro"
            className="block text-sm font-medium text-gray-700"
          >
            Intro
          </label>
          <textarea
            id="intro"
            name="intro"
            value={blogData.intro}
            onChange={handleInputChange}
            className="mt-1 p-2 border border-gray-300 rounded-md w-full"
            rows={3}
          />
        </div>

        <div className="mb-4">
          <h3 className="text-lg font-medium text-gray-700">Sections</h3>
          {blogData.sections.map((section, index) => (
            <div key={index} className="mb-2 border p-2 rounded-md">
              <div className="mb-2">
                <label
                  htmlFor={`sectionName-${index}`}
                  className="block text-sm font-medium text-gray-700"
                >
                  Section Name
                </label>
                <input
                  type="text"
                  id={`sectionName-${index}`}
                  value={section.name}
                  onChange={(e) =>
                    handleSectionChange(index, "name", e.target.value)
                  }
                  className="mt-1 p-2 border border-gray-300 rounded-md w-full"
                />
              </div>

              <div className="mb-2">
                <label
                  htmlFor={`sectionType-${index}`}
                  className="block text-sm font-medium text-gray-700"
                >
                  Section Type
                </label>
                <select
                  id={`sectionType-${index}`}
                  value={section.type}
                  onChange={(e) =>
                    handleSectionChange(index, "type", e.target.value)
                  }
                  className="mt-1 p-2 border border-gray-300 rounded-md w-full"
                >
                  <option value="text">Text</option>
                  <option value="video">Video</option>
                  <option value="image">Image</option>
                </select>
              </div>

              <div className="mb-2">
                {section.type === "text" ? (
                  <div>
                    <label
                      htmlFor={`sectionContent-${index}`}
                      className="block text-sm font-medium text-gray-700"
                    >
                      Section Content
                    </label>
                    <textarea
                      id={`sectionContent-${index}`}
                      value={section.content}
                      onChange={(e) =>
                        handleSectionChange(index, "content", e.target.value)
                      }
                      className="mt-1 p-2 border border-gray-300 rounded-md w-full"
                      rows={3}
                    />
                  </div>
                ) : (
                  // <div>
                  //   <label
                  //     htmlFor={`sectionUpload-${index}`}
                  //     className="block text-sm font-medium text-gray-700"
                  //   >
                  //     Upload {section.type}
                  //   </label>
                  //   <input
                  //     type="file"
                  //     id={`sectionUpload-${index}`}
                  //     accept={section.type === "video" ? "video/*" : "image/*"}
                  //     onChange={(e) => handleFileUpload(index, e)}
                  //     className="mt-1"
                  //   />
                  // </div>
                  <div>
                    <label
                      htmlFor={`sectionUpload-${index}`}
                      className="block text-sm font-medium text-gray-700"
                    >
                      Upload {section.type}
                    </label>
                    <input
                      type="file"
                      id={`sectionUpload-${index}`}
                      accept={section.type === "video" ? "video/*" : "image/*"}
                      // No onChange handler here!
                      className="mt-1"
                    />
                  </div>
                )}
              </div>
              <button
                type="button"
                onClick={() => removeSection(index)}
                className="bg-red-500 hover:bg-red-700 text-white font-bold py-1 px-2 rounded"
              >
                Remove Section
              </button>
            </div>
          ))}
          <button
            type="button"
            onClick={addSection}
            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
          >
            Add Section
          </button>
        </div>

        <button
          type="submit"
          className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded"
        >
          Submit
        </button>
      </form>
    </>
  );
}

export default BlogContentManager;
