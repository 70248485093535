import React, { useState } from "react";

import { createClient } from "@supabase/supabase-js";
import { BsWhatsapp } from "react-icons/bs";
import { MdOutlineSms, MdOutlineEmail } from "react-icons/md";
import { FaArrowRight, FaArrowLeft } from "react-icons/fa6";

import MeetingsHeader from "./MeetingsHeader";
import MeetingsForm from "./MeetingsForm";
// import hero2 from "../../img/hero-v2.png";
// import hero2 from "../../img/frame_2608224/frame_2608224_1x.webp";
import mobile_hero from "../../img/mobile_heropng.png";
import content from "../../data/content";
import VideoPlayer from "../VideoPlayer";

// const supabaseUrl = process.env.REACT_APP_SB_URL;
// const supabaseKey = process.env.REACT_APP_SB_KEY;
// const supabase = createClient(supabaseUrl, supabaseKey);

const MeetingsMobile = ({ scroll, width }) => {
  const pageName = "meetings_landing_page";
  const { features } = content[pageName];
  const { how_it_works } = content[pageName];
  const { benefits } = content[pageName];
  const { steps } = content[pageName];
  const { testimonials } = content[pageName];

  const testimonialKeys = Object.keys(testimonials);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [showVideo, setShowVideo] = useState(false); // Lifted state

  const handleToggleVideo = () => {
    setShowVideo(!showVideo);
  };

  const nextTestimonial = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === testimonialKeys.length - 1 ? 0 : prevIndex + 1
    );
  };

  const prevTestimonial = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? testimonialKeys.length - 1 : prevIndex - 1
    );
  };

  return (
    <div className="main bg-white text-black flex flex-col px-1">
      <MeetingsHeader width={width} scroll={scroll} />
      <div className="flex flex-col  justify-center w-[100%]  px-2 mt-[120px] ">
        <span className="text-[40px] leading-none font-black drop-shadow-[3px_3px_0px_#dbdbdb]">
          {content[pageName].hero.title}
        </span>
        <br />

        <span className="my-2 text-2xl">{content[pageName].hero.subtitle}</span>
        <button
          className="border-2 border-black rounded-md p-2 mt-4 font-bold text-lg shadow-[5px_5px_0px_0px_#1a202c] hover:bg-amber-400 w-[60%]"
          onClick={() => scroll("footer")}
        >
          Get Started
        </button>
      </div>
      {/* <div className="flex justify-center mt-10">
        <VideoPlayer videoSrc="/intro_video.mp4" posterSrc={mobile_hero} /> */}
      {/* <img src={mobile_hero} alt="hero" className="mt-[40px] w-[100%]" /> */}
      {/* </div> */}
      <div className="relative mt-10">
        <img
          src={"/frame_2608224_1x.webp"}
          // src={hero2}
          alt="Click to play video"
          className=""
          onClick={handleToggleVideo}
        />
        <div
          className="absolute top-4 right-6 flex items-center bg-white border-2 border-black font-bold shadow-[5px_5px_0px_0px_#1a202c] justify-center  text-black hover:bg-amber-400 hover:opacity-100 transition-opacity duration-300 p-2 cursor-pointer" // Changed to position at top right
          // onClick={handleImageClick}
          onClick={handleToggleVideo}
        >
          Play Video
        </div>
        {showVideo && (
          <VideoPlayer showVideo={showVideo} onToggle={handleToggleVideo} />
        )}
      </div>
      <div className="flex items-center flex-col px-2 bg-black mt-10 rounded-lg">
        <div
          className="flex flex-col text-white w-[100%] text-[40px] font-bold px-2 items-center  py-6  mt-4"
          id="how-minutes-work"
        >
          <span className="leading-10">
            {content[pageName].subsection.text_black}​{" "}
            {/* <span className="text-amber-400">
              {content[pageName].subsection.text_yellow}
            </span>
            <div>
              <p className="text-2xl mt-10">Works with</p>
              <div className="flex flex-row mt-2 justify-between px-10">
                <div className="flex flex-col items-center mr-8 w-[50px]">
                  <BsWhatsapp className="text-3xl mb-1" />
                  <span className="text-sm">Whatsapp</span>
                </div>
                <div className="flex flex-col items-center mr-8 w-[50px]">
                  <MdOutlineSms className="text-3xl mb-1" />
                  <span className="text-sm">SMS</span>
                </div>
                <div className="flex flex-col items-center w-[50px]">
                  <MdOutlineEmail className="text-3xl mb-1" />
                  <span className="text-sm">Email</span>
                </div>
              </div>
            </div> */}
          </span>
        </div>
        <div
          className="flex flex-row overflow-x-auto mb-[20px]  py-10 w-[100%] no-scrollbar section text-white "
          id="how-it-works"
        >
          {Object.values(steps).map((step, index) => (
            <div
              key={index}
              className="min-w-[280px] border-2 border-black py-4 h-[380px]  mx-4 text-center"
            >
              {step.image}
              <h1 className="font-bold text-[22px] my-2">
                {index + 1}- {step.title}
              </h1>
              <p className="text-md">{step.description}</p>
            </div>
          ))}
        </div>
      </div>
      <div className="border-t border-white rounded-xl  text-black  w-[100%] items-center flex flex-col mb-12 mt-10 p-2">
        <div className="flex w-full  justify-center items-center ">
          <span
            className="text-[40px] font-black px-4 mt-8 leading-10"
            id="features-mobile"
          >
            Everything you need in an Assistant
          </span>
        </div>
        {Object.values(features).map((feature, index) => (
          <div
            key={index}
            className="mt-14 flex justify-center flex-col  section border-2 border-black rounded-lg shadow-[5px_5px_0px_0px_#1a202c] "
            id={feature.id}
          >
            <div className="flex flex-col bg-gray-100 border-b-[2px] border-dashed border-black rounded-t-lg p-4">
              <span className="text-[24px] font-black">{feature.title}</span>
              <p className="mt-6">{feature.description}</p>
            </div>

            <div className="flex flex-row justify-center my-4">
              {feature.mobileImage}
            </div>
          </div>
        ))}
      </div>
      <div className="flex flex-row w-full justify-between items-center my-20 px-4">
        <div className="w-[10%]">
          <FaArrowLeft className="text-[20px]" onClick={prevTestimonial} />
        </div>
        <div className="flex w-[80%]">
          <div className="w-full">
            <p className="text-center text-[28px] font-bold">
              "{testimonials[testimonialKeys[currentIndex]].quote}"
            </p>
            <p className="text-center text-[20px] mt-4 text-gray-500 font-bold">
              - {testimonials[testimonialKeys[currentIndex]].by}
            </p>
          </div>
        </div>
        <div className="flex w-[10%] justify-end">
          <FaArrowRight className="text-[20px]" onClick={nextTestimonial} />
        </div>
      </div>
      {/* <div className="my-8">
        <div className="flex flex-col ">
          <h1
            className="text-4xl font-bold text-center mb-4 section"
            id="how-it-works-mobile"
          >
            How it works
          </h1>
          <div className="flex justify-center">{how_it_works.image}</div>
        </div>
        <div className="flex flex-row overflow-x-auto no-scrollbar w-[90%] px-6 mt-6 mb-12">
          {Object.values(how_it_works.steps).map((step, index) => (
            <div key={index} className="min-w-[300px]">
              <div className="flex p-1 border-2 border-black w-[40px] h-[40px] justify-center shadow-[5px_5px_0px_0px_#1a202c] mb-2">
                <h1 className="text-2xl font-bold">{step.step}</h1>
              </div>
              <p className="text-lg px-4 py-2">{step.description}</p>
            </div>
          ))}
        </div>
      </div> */}
      <MeetingsForm width={width} />
    </div>
  );
};

export default MeetingsMobile;
