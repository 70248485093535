import { React, useState, useEffect, useRef } from "react";

import Footer from "./components/Footer";

import { scrollToSection } from "./utils";
import MeetingsContent from "./components/Meetings-site/MeetingsContent";
import MeetingsHeader from "./components/Meetings-site/MeetingsHeader";
import MeetingsForm from "./components/Meetings-site/MeetingsForm";
import MeetingsMobile from "./components/Meetings-site/MeetingsMobile";

const Meetings = () => {
  const [width, setWidth] = useState(window.innerWidth);
  const aboutRef = useRef(null);

  useEffect(() => {
    const handleResize = () => {
      setWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <>
      {width < 660 ? (
        <div>
          <MeetingsMobile scroll={scrollToSection} width={width} />
          <Footer width={width} />
        </div>
      ) : (
        <div className="main bg-white w-full h-full  flex justify-center">
          <div className="2xl:max-w-[1440px] flex justify-center ">
            {/* <img
              src={hero_image}
              alt="hero"
              className="z-0 absolute top-0 right-0 2xl:right-[400px] w-[50%] 2xl:w-[30%]"
            /> */}
            <MeetingsHeader
              // scroll={scrollToSection}
              width={width}
            />
            <div>
              <div>
                <MeetingsContent aboutRef={aboutRef} />
              </div>
              <div id="footer section5" className="bg-white section">
                <MeetingsForm width={width} />
                <Footer width={width} />
                <div></div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Meetings;
