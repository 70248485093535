import { React, useState, useEffect } from "react";
import Header from "./components/Header";
import Content from "./components/Content";
import hero_image from "./img/hero-image.png";
import Form from "./components/Form";
import Footer from "./components/Footer";
import Mobile from "./components/Mobile";
import hero2 from "./img/hero-v2.png";
import { scrollToSection } from "./utils";

function PmAssistant() {
  const [width, setWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <>
      {width < 660 ? (
        <div>
          <Mobile scroll={scrollToSection} width={width} />
          <Footer width={width} />
        </div>
      ) : (
        <div className="main bg-white w-full h-full  flex justify-center">
          <div className="2xl:max-w-[1440px] flex justify-center ">
            {/* <img
              src={hero_image}
              alt="hero"
              className="z-0 absolute top-0 right-0 2xl:right-[400px] w-[50%] 2xl:w-[30%]"
            /> */}
            <Header scroll={scrollToSection} width={width} />
            <div>
              <div>
                <Content scroll={scrollToSection} />
              </div>
              <div id="footer section5" className="bg-white section">
                <Form width={width} />
                <Footer width={width} />
                <div></div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default PmAssistant;
