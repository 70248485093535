import { supabase } from "../utils.js";
// import { Client } from "pg";

export async function fetchData() {
  const { data, error } = await supabase.from("BLOGS").select("*"); // You can customize the select query as needed

  if (error) {
    throw error;
  }

  // return data;
  return data;
}

export async function fetchAuthors() {
  const { data, error } = await supabase.from("AUTHORS").select("*"); // You can customize the select query as needed

  if (error) {
    throw error;
  }

  return data;
}

export async function createBlog(blog) {
  const { error } = await supabase
    .from("BLOGS")
    .insert({ JSON: JSON.stringify(blog) });

  if (error) {
    throw error;
  }
}

// Upload file using standard upload
export async function uploadFile(file) {
  // Generate a unique filename to avoid overwrites. You can customize this as needed.
  const fileName = `${Date.now()}-${file.name}`;

  const { data, error } = await supabase.storage
    // .from("Blogs_images") // Your bucket name
    // .upload(`private/${fileName}`, file);
    .from("Blog_images_public")
    .upload(fileName, file);

  if (error) {
    console.error("Error uploading file:", error); // Log the error to the console
    // You can add more specific error handling here, like showing an error message to the user
    throw error; // Optionally re-throw the error for further handling
  } else {
    const url = `https://qfktimnmlcnfowxuoune.supabase.co/storage/v1/object/public/Blog_images_public/${fileName}`;

    return url;
  }
  // else {
  //   // Get a signed URL with a 10-year lifespan (in seconds)
  //   const lifespanInSeconds = 10 * 365 * 24 * 60 * 60; // 10 years

  //   const { data: signedUrlData, error: signedUrlError } =
  //     await supabase.storage
  //       .from("Blogs_images")
  //       .createSignedUrl(fileName, lifespanInSeconds);

  //   if (signedUrlError) {
  //     console.error("Error generating signed URL:", signedUrlError);
  //     // Handle the error appropriately (e.g., show an error message, retry, etc.)
  //     throw signedUrlError; // Optionally re-throw for further handling
  //   } else {
  //     const signedUrl = signedUrlData.signedURL;
  //     console.log(
  //       "File uploaded successfully. Signed URL (10 years):",
  //       signedUrl
  //     );
  //     return signedUrl; // Return the signed URL
  //   }
  // }
}
