import React from "react";
import logo from "../img/logo.svg";

//react footer component

const Footer = ({ width }) => {
  return (
    <>
      {width > 660 ? (
        <div className="bg-white py-8 flex flex-row justify-around">
          <img src={logo} alt="logo" className="w-[10%]" />
          <div className="flex flex-col">
            <p>Copyright © 2024 CodeStory, Inc.</p>
            <p>441 Jane St, Toronto, ON, M6S3Z9</p>
            <p>roberto@flowlly.com</p>
          </div>
        </div>
      ) : (
        <div className="bg-white  py-8 flex flex-col items-center">
          <img src={logo} alt="logo" className="w-[50%]" />
          <div className="flex flex-col text-center mt-2">
            <p>Copyright © 2024 CodeStory, Inc.</p>
            <p>CodeStory, Inc.</p>
            <p>441 Jane St, Toronto, ON, M6S3Z9</p>
          </div>
        </div>
      )}
    </>
  );
};

export default Footer;
