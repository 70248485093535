import React from "react";

import SafetyVerticalMenu from "./SafetyVerticalMenu";
import hero2 from "../../img/hero-v2.png";
import content from "../../data/content";

const SafetyContent = ({ scroll }) => {
  const pageName = "safety_page";
  const { features } = content[pageName];
  const { how_it_works } = content[pageName];

  return (
    <div className="border-l border-white  mt-48 text-black font-league flex flex-col px-20">
      <div>
        <div className="flex flex-row justify-between">
          <div className=" min-h-[550px] w-3/6">
            <h1 className="text-6xl font-bold mb-2">
              {content[pageName].hero.title}
              {/* Your Handy Construction Sidekick */}
            </h1>
            <p className="text-3xl w-[80%]">
              {content[pageName].hero.subtitle}
            </p>
            <button
              className="border-2 border-black p-2 mt-4 font-bold text-lg shadow-[5px_5px_0px_0px_#1a202c] hover:bg-amber-400"
              onClick={() => scroll("footer")}
            >
              Get Started
            </button>
          </div>
          <div className="w-3/6">
            <img
              src={hero2}
              alt="hero"
              className="top-36 w-[100%] 2xl:w-[90%]"
            />
          </div>
        </div>

        <div>
          <h1 className="text-5xl font-bold mb-4 w-full text-center">
            {content[pageName].subsection.text_black}​{" "}
            <span className="text-amber-400">
              {content[pageName].subsection.text_yellow}
            </span>
          </h1>
          <div
            className="flex flex-row mt-16 section justify-center"
            id="inspection-benefits"
          >
            {Object.values(content[pageName].benefits).map((benefit) => (
              <div
                key={benefit.title}
                className="w-1/4 border-2 border-black p-4 h-[350px] shadow-[5px_5px_0px_0px_#1a202c] mx-4"
              >
                {benefit.icon}
                <h1 className="font-bold text-[22px] mb-2">{benefit.title}</h1>
                <p className="text-lg">{benefit.description}</p>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="flex flex-row">
        <div className="w-[90%] mt-20">
          {Object.values(features).map((feature, index) => (
            <div key={index} className="min-h-[832px] mt-20">
              <div
                className="mt-36 flex flex-row items-center section"
                id={feature.id}
              >
                <div className="w-2/5 text-2xl">
                  <div className="flex flex-row mb-2">
                    {feature.keywords.map((keyword, index) => (
                      <div
                        key={index}
                        className="bg-yellow-400 rounded-full text-[12px] text-black mr-1 px-4 flex justify-center mt-2 mb-2"
                      >
                        {keyword}
                      </div>
                    ))}
                  </div>
                  <p className="font-semibold">{feature.title}</p>
                  <p className="mt-6">{feature.description}</p>
                </div>
                <div className="w-3/5 flex justify-center px-12">
                  {feature.image}
                </div>
              </div>
            </div>
          ))}
        </div>
        <SafetyVerticalMenu className="w-[3%]" scroll={scroll} />
      </div>
      <div className="flex flex-row ">
        <div className="w-3/6 ">
          <div className="sticky top-[200px]">
            <h1
              className="text-6xl font-bold section "
              id="how-reporting-works"
            >
              How it works
            </h1>
            {how_it_works.image}
          </div>
        </div>
        <div className="flex flex-col w-3/6">
          {Object.values(how_it_works.steps).map((step, index) => (
            <div key={index} className={`mt-${index === 0 ? 0 : 6}`}>
              <div className="flex p-1 border-2 border-black w-[40px] h-[40px] justify-center shadow-[5px_5px_0px_0px_#1a202c] mb-2">
                <h1 className="text-2xl font-bold">{step.step}</h1>
              </div>
              <p className="text-2xl px-4 py-2">{step.description}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default SafetyContent;
