import { React, useState, useEffect, useRef } from "react";

import MeetingsVerticalMenu from "./MeetingsVerticalMenu";
// import hero2 from "../../img/hero-v2.png";
// import hero2 from "../../img/frame_2608224/frame_2608224_1x.webp";
import heroRounded from "../../img/hero-image-rounded.png";
import content from "../../data/content";
import VideoPlayer from "../VideoPlayer";
import { FaArrowLeft, FaArrowRight } from "react-icons/fa";

const MeetingsContent = ({ scroll, aboutRef }) => {
  const pageName = "meetings_landing_page";
  const { features } = content[pageName];
  const { steps } = content[pageName];
  const { how_it_works } = content[pageName];
  const [stepSelected, setStepSelected] = useState(0);
  const { testimonials } = content[pageName];
  const testimonialKeys = Object.keys(testimonials);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isPaused, setIsPaused] = useState(false);
  const intervalRef = useRef(null);
  const [showVideo, setShowVideo] = useState(false); // Lifted state

  const handleToggleVideo = () => {
    setShowVideo(!showVideo);
  };

  const nextTestimonial = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === testimonialKeys.length - 1 ? 0 : prevIndex + 1
    );
  };

  const prevTestimonial = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? testimonialKeys.length - 1 : prevIndex - 1
    );
  };

  // useEffect(() => {
  //   const intervalId = setInterval(() => {
  //     setStepSelected(
  //       (prevIndex) => (prevIndex + 1) % Object.keys(steps).length
  //     );
  //   }, 5000); // Change every 3 seconds

  //   return () => clearInterval(intervalId); // Cleanup on component unmount
  // }, [steps]);

  useEffect(() => {
    if (!isPaused) {
      intervalRef.current = setInterval(() => {
        setStepSelected(
          (prevIndex) => (prevIndex + 1) % Object.keys(steps).length
        );
      }, 3000);
    } else {
      clearInterval(intervalRef.current);
    }

    return () => clearInterval(intervalRef.current);
  }, [steps, isPaused]);

  const stepSelection = (index) => {
    setStepSelected(index);
    setIsPaused(true);
  };

  return (
    <div className="border-l border-white  mt-48 text-black font-league flex flex-col px-20">
      <div>
        <div className="flex flex-row justify-between">
          <div className=" min-h-[550px] w-3/6 pt-[35px]">
            <h1 className="text-6xl font-bold mb-2 w-[80%] drop-shadow-[3px_3px_0px_#dbdbdb]">
              {content[pageName].hero.title}
              {/* Your Handy Construction Sidekick */}
            </h1>
            <p className="text-3xl w-[80%]">
              {content[pageName].hero.subtitle}
            </p>
            <button
              className="border-2 border-black p-2 mt-4 font-bold text-lg shadow-[5px_5px_0px_0px_#1a202c] hover:bg-amber-400"
              onClick={() => scroll("footer")}
            >
              Get Started
            </button>
          </div>
          <div className="relative w-3/6">
            <img
              src={"/frame_2608224_1x.webp"}
              alt="Click to play video"
              className="cursor-pointer"
              onClick={handleToggleVideo}
            />
            <div
              className="absolute top-8 right-6 flex items-center bg-white border-2 border-black font-bold shadow-[5px_5px_0px_0px_#1a202c] justify-center  text-black hover:bg-amber-400 hover:opacity-100 transition-opacity duration-300 p-2 cursor-pointer" // Changed to position at top right
              // onClick={handleImageClick}
              onClick={handleToggleVideo}
            >
              Play Video
            </div>
            {showVideo && (
              <VideoPlayer showVideo={showVideo} onToggle={handleToggleVideo} />
            )}
          </div>
        </div>

        <div className="flex flex-col bg-black rounded-3xl p-[70px]">
          <div>
            <h1 className="text-6xl font-bold mb-4 w-full text-white">
              {content[pageName].subsection.text_black}​{" "}
              {/* <span className="text-amber-400">
                {content[pageName].subsection.text_yellow}
              </span> */}
            </h1>
          </div>
          <div className="flex flex-row mt-10" id="how-it-works">
            <div className="w-1/2">
              {Object.values(steps).map((step, index) => (
                <div
                  onClick={() => stepSelection(index)}
                  className={`${
                    stepSelected === index ? "text-white" : "text-zinc-600"
                  } mb-6 pr-6 cursor-pointer`}
                >
                  <span className="text-4xl font-bold hover:text-amber-400">
                    {step.title}
                  </span>
                  <p
                    className={`text-2xl ${
                      stepSelected === index ? "" : "hidden"
                    }`}
                  >
                    {step.description}
                  </p>
                </div>
              ))}
            </div>
            <div className="w-1/2 pl-6">
              {Object.values(steps)[stepSelected].image}
            </div>
          </div>
        </div>
      </div>
      <div className="flex w-full  justify-center items-center my-20">
        <span className="text-6xl font-bold ">
          Everything you need in an Assistant
        </span>
      </div>

      <div className="flex flex-col" id="every-detail">
        <div
          id={features[1].id}
          className="border-4 border-black rounded-3xl flex flex-col bg-white shadow-[5px_5px_0px_0px_#1a202c] my-2 mx-4"
        >
          <div className="flex-grow  flex flex-col">
            <div className="px-6 pt-6 pb-6 bg-gray-100 rounded-t-3xl flex flex-row border-b-[2px] border-dashed border-black">
              <div className="flex flex-col mb-4">
                <div className="flex flex-row">
                  <div className="flex justify-center items-center w-12 h-12 border-2 border-black rounded-full text-lg font-bold bg-white ml-4">
                    01
                  </div>
                  <h1 className="text-2xl font-bold text-black mb-2 ml-4  mt-4">
                    {features[1].title}
                  </h1>
                </div>
                <p className="text-xl px-20">{features[1].description}</p>
              </div>
            </div>
            <div className="flex-grow flex justify-center items-center mt-4">
              {features[1].image}
            </div>
          </div>
        </div>

        <div className="flex flex-row flex-wrap justify-center">
          {Object.values(features)
            .slice(1) // This skips the first item (index 0)
            .map((feature, index) => (
              <div
                key={index + 1} // Adjust the key to reflect the correct index
                id={feature.id}
                className="border-4 border-black rounded-3xl flex flex-col w-[48%] bg-white shadow-[5px_5px_0px_0px_#1a202c] m-2"
              >
                <div className="px-4 pt-4 bg-gray-100 rounded-t-3xl flex flex-col border-b-[2px] border-dashed border-black h-[220px]">
                  <div class="flex flex-row items-center mb-4">
                    <div class="flex justify-center items-center w-12 h-12 border-2 border-black rounded-full text-lg font-bold bg-white">
                      0{index + 2}
                    </div>

                    <h1 class="text-2xl font-bold text-black mb-2 ml-4  mt-4">
                      {" "}
                      {/* Add some spacing between the elements */}
                      {feature.title}
                    </h1>
                  </div>

                  <p className="text-xl pl-16">{feature.description}</p>
                </div>
                <div className="flex-grow my-4 flex flex-col ">
                  <div className="flex-grow flex justify-center items-center">
                    {feature.image}
                  </div>
                </div>
              </div>
            ))}
        </div>
      </div>
      <div className="flex flex-row w-full justify-between items-center my-20">
        <div className="w-[10%]">
          <FaArrowLeft className="text-6xl" onClick={prevTestimonial} />
        </div>
        <div className="flex w-[80%]">
          <div className="w-full">
            <p className="text-center text-6xl font-bold">
              "{testimonials[testimonialKeys[currentIndex]].quote}"
            </p>
            <p className="text-center text-3xl mt-4 text-gray-500 font-bold">
              - {testimonials[testimonialKeys[currentIndex]].by}
            </p>
          </div>
        </div>
        <div className="flex w-[10%] justify-end">
          <FaArrowRight className="text-6xl" onClick={nextTestimonial} />
        </div>
      </div>
    </div>
  );
};

export default MeetingsContent;
