import { React, useState, useEffect } from "react";

import Footer from "./components/Footer";

import { scrollToSection } from "./utils";

import CommunicationContent from "./components/communication-site/CommunicationContent";
import CommunicationHeader from "./components/communication-site/CommunicationHeader";
import CommunicationForm from "./components/communication-site/CommunicationForm";
import CommunicationMobile from "./components/communication-site/CommunicationMobile";

const Communication = () => {
  const [width, setWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <>
      {width < 660 ? (
        <div>
          <CommunicationMobile scroll={scrollToSection} width={width} />
          <Footer width={width} />
        </div>
      ) : (
        <div className="main bg-white w-full h-full  flex justify-center">
          <div className="2xl:max-w-[1440px] flex justify-center ">
            {/* <img
              src={hero_image}
              alt="hero"
              className="z-0 absolute top-0 right-0 2xl:right-[400px] w-[50%] 2xl:w-[30%]"
            /> */}
            <CommunicationHeader scroll={scrollToSection} width={width} />
            <div>
              <div>
                <CommunicationContent />
              </div>
              <div id="footer section5" className="bg-white section">
                <CommunicationForm width={width} />
                <Footer width={width} />
                <div></div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Communication;
