import React, { useState, useEffect } from "react";
import MeetingsHeader from "./components/Meetings-site/MeetingsHeader";
import logo from "../src/img/logo.svg";
import thank_you_image from "../src/img/thank-you.png";
import { Link } from "react-router-dom";

const ThankYou = () => {
  const [width, setWidth] = useState(window.innerWidth);
  const [scrollPosition, setScrollPosition] = useState(0);

  useEffect(() => {
    const handleResize = () => {
      setWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <>
      {width > 660 ? (
        <div className="flex flex-col">
          <div className="py-5 px-20"></div>
          <div className="grid grid-cols-2 items-center mt-8">
            <div className="py-10 pl-24">
              <p className="text-4xl font-bold">Thank you!</p>
              <p className="text-2xl">
                We'll reach out to you shortly, check your eamil, and spam
                folder just in case.
              </p>
              <Link to="/">
                <img src={logo} alt="logo" className={`w-[60%] mt-4`} />
              </Link>
              <Link to="/">
                <div className="text-black text-xl font-bold px-3 py-2 border-2 border-black  hover:bg-amber-400 hover:cursor-pointer bg-white shadow-[5px_5px_0px_0px_#1a202c] mt-8 inline-block ">
                  Take me back home
                </div>
              </Link>
            </div>
            <div className=" flex justify-center">
              <img src={thank_you_image} alt="thank you" className="w-[80%]" />
            </div>
          </div>
        </div>
      ) : (
        <div className="flex flex-col">
          <div className="ml-4">
            <Link to="/">
              <img src={logo} alt="logo" className={`w-[50%] mt-4`} />
            </Link>
          </div>
          <div className="py-5 px-20"></div>
          <div className="flex flex-col items-center mt-8">
            <div className=" flex justify-center">
              <img src={thank_you_image} alt="thank you" className="w-[80%]" />
            </div>
            <div className="flex flex-col items-center py-10">
              <p className="text-4xl font-bold">Thank you!</p>
              <p className="text-2xl text-center">
                We'll reach out to you shortly, check your eamil, and spam
                folder just in case.
              </p>
              <Link to="/">
                <div className="text-black text-xl font-bold px-3 py-2 border-2 border-black  hover:bg-amber-400 hover:cursor-pointer bg-white shadow-[5px_5px_0px_0px_#1a202c] mt-8 inline-block ">
                  Take me back home
                </div>
              </Link>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ThankYou;
