import React from "react";
import { BiSolidTimer } from "react-icons/bi";
import { FaEye } from "react-icons/fa";
import { GiTalk } from "react-icons/gi";
import { TbShieldCheck } from "react-icons/tb";
import daily from "../img/daily-report.png";
import schedule from "../img/schedule-management.png";
import multilingual from "../img/multilingual2.png";
import howitworks from "../img/how-it-works.png";
import { BsClipboard2CheckFill } from "react-icons/bs";
import { GrTableAdd } from "react-icons/gr";
import multiformat from "../img/multiformat.png";
import InspectionsHowitworks from "../img/inspection-how-it-works.png";
import { BiTargetLock } from "react-icons/bi";
import submittal from "../img/submittal.png";
import review from "../img/review.png";
import data_extraction from "../img/data_extraction.png";
import { TbBarrierBlockOff } from "react-icons/tb";
import { RiTeamFill } from "react-icons/ri";
import translation from "../img/translation.png";
import onetoone from "../img/1to1 communication.png";
import companycom from "../img/compoany communication.png";
import groupcom from "../img/group communication.png";
import communication from "../img/communication.png";
import inperson from "../img/inperson_online.png";
import click_record from "../img/click_record.png";
import minutes from "../img/minutes.png";
import tasks_review from "../img/tasks_review.png";
import share from "../img/share.png";
import updates from "../img/updates.png";
import record_gif from "../img/Record Meeting.gif";
import tasks_gif from "../img/adding tasks gif.gif";
import lastword_gif from "../img/last word.gif";
import integrations_gif from "../img/integrations gif.gif";
import details from "../img/minutes_details.png";
import mobileDetails from "../img/multiformat_gif.gif";

const content = {
  daily_landing_page: {
    hero: {
      title: "Real-Time Updates, Anytime, Anywhere",
      subtitle:
        "Simplify daily reporting, enhance real-time project visibility, and keep your schedules up to date with Flowlly.",
    },
    subsection: {
      text_black: "Updating your schedule and creating daily reports is",
      text_yellow:
        "as quick and easy as sending a voice message to an assistant.",
    },
    benefits: {
      1: {
        title: "Improved Productivity",
        description:
          "Your team spends less time distracted on papework and more time solving problems",
        icon: <BiSolidTimer className="text-[60px] mb-4" />,
      },
      2: {
        title: "Real-time updates and visibility",
        description:
          " Real-time documentation of project progress, issues, and site conditions, allowing the team and schedule to stay updated on happenings at the job site.",
        icon: <FaEye className="text-[60px] mb-4" />,
      },
      3: {
        title: "Complete documentation and traceability",
        description:
          "  Detailed timestamped reports with photos/videos that serve as valuable documentation and can help defend against unmerited claims or disputes.",
        icon: <TbShieldCheck className="text-[60px] mb-4" />,
      },
      4: {
        title: "Improved communication",
        description:
          "It doesn’t matter what languages the crew speaks, Flowlly can communicate tasks and get updates in their native languages.",
        icon: <GiTalk className="text-[60px] mb-4" />,
      },
    },
    features: {
      1: {
        id: "section1",
        image: (
          <img src={daily} alt="automated daily reports" className="w-[80%]" />
        ),
        keywords: [
          "Improved Productivity",
          "Complete Documentation",
          "Traceability",
        ],
        title: "AUTOMATED DAILY REPORTS",
        description:
          "No more incomplete daily reports due to a lack of time. Just send voice notes, videos, and images as you work, and our assistant will compile a detailed daily report for you.",
      },
      2: {
        id: "section2",
        image: (
          <img src={schedule} alt="schedule management" className="w-[80%]" />
        ),
        keywords: ["Real-time Visibility", "Improved Communication"],
        title: "AUTOMATED SCHEDULE MANAGEMENT",
        description:
          "   Ensure that the schedule is always current and foster efficient and timely communication among all project stakeholders. Flowly automatically analyzes the field team's input to identify impacts on the schedule and updates it accordingly.",
      },
      3: {
        id: "section3",
        image: <img src={multilingual} alt="reporting" className="w-[80%]" />,
        keywords: ["Improved Communication", "Complete Documentation"],
        title: "BREAKING THE LANGUAGE BARRIER",
        description:
          "No more incomplete reports due to language barriers. Your team can provide input in the language they feel more comfortable with ensuring that no relveant information is 'lost in translation'.",
      },
    },
    how_it_works: {
      image: <img src={howitworks} alt="how it works" className="w-[90%]" />,
      steps: {
        1: {
          step: "1",
          description:
            "Flowlly connects to your system of record or scheduling software to retrieve the schedule and directory.",
        },
        2: {
          step: "2",
          description:
            "Users provide information via WhatsApp, SMS, Teams, or email in the form of voice notes, videos, texts, and images.",
        },
        3: {
          step: "3",
          description:
            "If needed, Flowlly requests additional information from the users.",
        },
        4: {
          step: "4",
          description:
            "  Flowlly automatically processes the information and generates reports and schedule impact analysis for review and approval.",
        },
        5: {
          step: "5",
          description:
            "Main user reviews and approves reports and schedule impact.",
        },
        6: {
          step: "6",
          description:
            "   Flowlly syncs reports and schedule updates to systems of record and/or scheduling software.",
        },
      },
    },
  },
  inspection_page: {
    hero: {
      title: "Generate Inspection Reports in Seconds",
      subtitle:
        "Use your smartphone to record site details through video, voice and photos. Send it to Flowlly and have your inspection report done before you're back at the office.",
    },
    subsection: {
      text_black: "You do the inspection,",
      text_yellow: "Flowlly does the report writing",
    },
    benefits: {
      1: {
        title: "Improved Productivity",
        description:
          "Spend more time doing detailed inspections and capture any detail with video, images, and voice. We will take all that and turn it into a detailed report.",
        icon: <BsClipboard2CheckFill className="text-[60px] mb-4" />,
      },
      2: {
        title: "Speedup Process",
        description:
          "Significantly reduce the time between conducting an inspection and delivering the final report to clients or stakeholders.",
        icon: <BiSolidTimer className="text-[60px] mb-4" />,
      },
      3: {
        title: "More Inspections",
        description:
          "Time saved from streamlined report writing can directly translate to an increase in the number of inspections conducted within the same timeframe. pen_spark",
        icon: <GrTableAdd className="text-[60px] mb-4" />,
      },
    },
    features: {
      1: {
        id: "inspection-reports",
        image: (
          <img src={daily} alt="automated daily reports" className="w-[80%]" />
        ),
        keywords: ["Detailed Rports", "Speedup Process", "More Inspections"],
        title: "AUTOMATED INSPECTION REPORTS",
        description:
          "Stop spending hours writing inspection reports. Just send voice notes, videos, and images as you work, and our assistant will compile a detailed report for you.",
      },
      2: {
        id: "multiformat",
        image: (
          <img
            src={multiformat}
            alt="schedule management"
            className="w-[80%]"
          />
        ),
        keywords: ["Detailed Reports", "Speedup Process"],
        title: "MULTI-FORMAT REPORTING",
        description:
          "Why sticking to just one reporting format? Flowlly can generate different 'versions' of the same report depending on who will be consuming it. For example, it will automatically generate a task list for the project manager, a summary for the client, and a detailed report for the team.",
      },
      3: {
        id: "multilingual-inspection",
        image: <img src={multilingual} alt="reporting" className="w-[80%]" />,
        keywords: ["Detailed Reports", "More Inspections"],
        title: "BREAKING LANGUAGE BARRIERS",
        description:
          "Don't let language limit your reports impact. Flowlly can take your voice notes and videos in any language and translate them into a report in the language choice.",
      },
    },
    how_it_works: {
      image: (
        <img
          src={InspectionsHowitworks}
          alt="how it works"
          className="w-[90%]"
        />
      ),
      steps: {
        1: {
          step: "1",
          description: "Flowlly connects to your system of record.",
        },
        2: {
          step: "2",
          description:
            "Inspector provide voice notes, videos, texts, and images of their inspections via WhatsApp, SMS, Teams, or email.",
        },
        3: {
          step: "3",
          description:
            "Flowlly automatically processes the information and generates reports for review, approval and distribution.",
        },
        4: {
          step: "4",
          description: "Inspector reviews and approves reports.",
        },
        5: {
          step: "5",
          description:
            "Flowlly syncs reports to systems of record and if needed distributes a PDF version.",
        },
      },
    },
  },
  qaqc_page: {
    hero: {
      title: "Generate Quality Reports and Punchlists in Seconds",
      subtitle:
        "Use your smartphone to record quality details through video, voice and photos. Send it to Flowlly and have your quality report and dynamic punchlists done before you're back at the office.",
    },
    subsection: {
      text_black: "You identify the quality issues,",
      text_yellow: "Flowlly does the report and punchlist writing",
    },
    benefits: {
      1: {
        title: "Improve Quality",
        description:
          "Identify and resolve construction defects early, ensuring projects meet or exceed quality standards. This leads to a higher standard of workmanship and a final product that meets or exceeds client expectations.",
        icon: <BsClipboard2CheckFill className="text-[60px] mb-4" />,
      },
      2: {
        title: "Reduce Risk",
        description:
          "Proactively identify potential risks, such as safety hazards, material defects, or schedule delays. By addressing these risks early, teams can minimize the impact on project timelines, budgets, and safety.",
        icon: <BiSolidTimer className="text-[60px] mb-4" />,
      },
      3: {
        title: "Increase Efficiency",
        description:
          "Automate time-consuming tasks like data collection, report generation, and communication. This frees up your team to focus on critical decision-making and value-added activities, ultimately saving time and resources.",
        icon: <GrTableAdd className="text-[60px] mb-4" />,
      },
    },
    features: {
      1: {
        id: "inspection-reports",
        image: (
          <img src={daily} alt="automated daily reports" className="w-[80%]" />
        ),
        keywords: ["Improved Quality", "Increase Efficiency"],
        title: "STANDARIZED FIELD DATA CAPTURE",
        description:
          "Automated collection, sharing, and reporting of inspection data directly from the field eliminate manual errors, speed up issue resolution, and reduce project risk by ensuring consistent and accurate data capture.",
      },
      2: {
        id: "multiformat",
        image: (
          <img
            src={multiformat}
            alt="schedule management"
            className="w-[80%]"
          />
        ),
        keywords: ["Detailed Reports", "Speedup Process"],
        title: "MULTI-FORMAT REPORTING",
        description:
          "Why sticking to just one reporting format? Flowlly can generate different 'versions' of the same report depending on who will be consuming it. For example, it will automatically generate a task list for the project manager, a summary for the client, and a detailed report for the team.",
      },
      3: {
        id: "multilingual-inspection",
        image: <img src={multilingual} alt="reporting" className="w-[80%]" />,
        keywords: ["Detailed Reports", "More Inspections"],
        title: "BREAKING LANGUAGE BARRIERS",
        description:
          "Don't let language limit your reports impact. Flowlly can take your voice notes and videos in any language and translate them into a report in the language choice.",
      },
    },
    how_it_works: {
      image: (
        <img
          src={InspectionsHowitworks}
          alt="how it works"
          className="w-[90%]"
        />
      ),
      steps: {
        1: {
          step: "1",
          description: "Flowlly connects to your system of record.",
        },
        2: {
          step: "2",
          description:
            "Inspector provide voice notes, videos, texts, and images of their inspections via WhatsApp, SMS, Teams, or email.",
        },
        3: {
          step: "3",
          description:
            "Flowlly automatically processes the information and generates reports for review, approval and distribution.",
        },
        4: {
          step: "4",
          description: "Inspector reviews and approves reports.",
        },
        5: {
          step: "5",
          description:
            "Flowlly syncs reports to systems of record and if needed distributes a PDF version.",
        },
      },
    },
  },
  safety_page: {
    hero: {
      title: "Generate Quality Reports and Punchlists in Seconds",
      subtitle:
        "Use your smartphone to record quality details through video, voice and photos. Send it to Flowlly and have your quality report and dynamic punchlists done before you're back at the office.",
    },
    subsection: {
      text_black: "You identify the quality issues,",
      text_yellow: "Flowlly does the report and punchlist writing",
    },
    benefits: {
      1: {
        title: "Improve Quality",
        description:
          "Identify and resolve construction defects early, ensuring projects meet or exceed quality standards. This leads to a higher standard of workmanship and a final product that meets or exceeds client expectations.",
        icon: <BsClipboard2CheckFill className="text-[60px] mb-4" />,
      },
      2: {
        title: "Reduce Risk",
        description:
          "Proactively identify potential risks, such as safety hazards, material defects, or schedule delays. By addressing these risks early, teams can minimize the impact on project timelines, budgets, and safety.",
        icon: <BiSolidTimer className="text-[60px] mb-4" />,
      },
      3: {
        title: "Increase Efficiency",
        description:
          "Automate time-consuming tasks like data collection, report generation, and communication. This frees up your team to focus on critical decision-making and value-added activities, ultimately saving time and resources.",
        icon: <GrTableAdd className="text-[60px] mb-4" />,
      },
    },
    features: {
      1: {
        id: "inspection-reports",
        image: (
          <img src={daily} alt="automated daily reports" className="w-[80%]" />
        ),
        keywords: ["Improved Quality", "Increase Efficiency"],
        title: "STANDARIZED FIELD DATA CAPTURE",
        description:
          "Automated collection, sharing, and reporting of inspection data directly from the field eliminate manual errors, speed up issue resolution, and reduce project risk by ensuring consistent and accurate data capture.",
      },
      2: {
        id: "multiformat",
        image: (
          <img
            src={multiformat}
            alt="schedule management"
            className="w-[80%]"
          />
        ),
        keywords: ["Detailed Reports", "Speedup Process"],
        title: "MULTI-FORMAT REPORTING",
        description:
          "Why sticking to just one reporting format? Flowlly can generate different 'versions' of the same report depending on who will be consuming it. For example, it will automatically generate a task list for the project manager, a summary for the client, and a detailed report for the team.",
      },
      3: {
        id: "multilingual-inspection",
        image: <img src={multilingual} alt="reporting" className="w-[80%]" />,
        keywords: ["Detailed Reports", "More Inspections"],
        title: "BREAKING LANGUAGE BARRIERS",
        description:
          "Don't let language limit your reports impact. Flowlly can take your voice notes and videos in any language and translate them into a report in the language choice.",
      },
    },
    how_it_works: {
      image: (
        <img
          src={InspectionsHowitworks}
          alt="how it works"
          className="w-[90%]"
        />
      ),
      steps: {
        1: {
          step: "1",
          description: "Flowlly connects to your system of record.",
        },
        2: {
          step: "2",
          description:
            "Inspector provide voice notes, videos, texts, and images of their inspections via WhatsApp, SMS, Teams, or email.",
        },
        3: {
          step: "3",
          description:
            "Flowlly automatically processes the information and generates reports for review, approval and distribution.",
        },
        4: {
          step: "4",
          description: "Inspector reviews and approves reports.",
        },
        5: {
          step: "5",
          description:
            "Flowlly syncs reports to systems of record and if needed distributes a PDF version.",
        },
      },
    },
  },
  submittal_page: {
    hero: {
      title: "Generate your Submittals in Seconds",
      subtitle:
        "Flowlly ensures submittal completeness and compliance by automatically extracting data from project documents and flagging discrepancies, missing info, or conflicts.",
    },
    subsection: {
      text_black: "You focus on building,",
      text_yellow: "Flowlly focus on your submittals",
    },
    benefits: {
      1: {
        title: "Improved Efficiency",
        description:
          "Significantly reduces the time and effort required to manage submittals, freeing up project teams to focus on other critical tasks.",
        // icon: <BsClipboard2CheckFill className="text-[60px] mb-4" />,
        icon: <BiSolidTimer className="text-[60px] mb-4" />,
      },
      2: {
        title: "Enhanced Accuracy",
        description:
          "Reduces errors and omissions through automated data extraction, validation, and quality checks. Ensures that submittals are complete and compliant with project specifications.",
        icon: <BiTargetLock className="text-[60px] mb-4" />,
      },
      3: {
        title: "Risk Mitigation",
        description:
          "Ensures compliance with regulatory requirements and project standards, reducing the risk of legal and financial penalties.",
        icon: <TbShieldCheck className="text-[60px] mb-4" />,
      },
    },
    features: {
      1: {
        id: "inspection-reports",
        image: (
          <img
            src={data_extraction}
            alt="automated daily reports"
            className="w-[80%]"
          />
        ),
        keywords: ["Improved Efficiency", "Enhaced Accuracy"],
        title: "AUTOMATED DATA EXTRACTION",
        description:
          "Automatically extract relevant data from various project documents, including specifications, drawings, and contract requirements. This eliminates the need for manual data entry, reducing errors and saving time.",
      },
      2: {
        id: "multiformat",
        image: (
          <img src={review} alt="schedule management" className="w-[80%]" />
        ),
        keywords: ["Enhaced Accuracy", "Risk Mitigation"],
        title: "INTELLIGENT REVIEW AND ANALYSIS",
        description:
          "Automatically analyze submittals against project requirements, flagging discrepancies, missing information, or potential conflicts. This helps identify issues early on, preventing costly rework later.",
      },
    },
    how_it_works: {
      image: <img src={submittal} alt="how it works" className="w-[90%]" />,
      steps: {
        1: {
          step: "1",
          description:
            "Flowlly connects to your system of record, email, and schedule.",
        },
        2: {
          step: "2",
          description:
            "Flowlly seamlessly collects and examines the required data for submission.",
        },
        3: {
          step: "3",
          description:
            "Preeliminary draft is presented for review and approval.",
        },
        4: {
          step: "4",
          description: "User reviews and approves documents.",
        },
        5: {
          step: "5",
          description:
            "Flowlly syncs submittal back to construction management platform or sends it for approval.",
        },
      },
    },
  },
  communication_page: {
    hero: {
      title: "Communicate with your crew in ",
      languages: [
        "english",
        "español",
        "हिन्दी",
        "français",
        "português",
        "中文",
        "日本語",
        "한국어",
        "Русский",
        "italiano",
        "العربية",
      ],
      subtitle: "Don't let differences in languages affect your projects.",
    },
    subsection: {
      text_black: "You focus on building,",
      text_yellow: "Flowlly does the translation",
    },
    benefits: {
      1: {
        title: "Zero Confusion",
        description:
          "Break down language barriers, ensuring everyone understands instructions, safety protocols, and project details for seamless collaboration.",
        // icon: <BsClipboard2CheckFill className="text-[60px] mb-4" />,
        icon: <TbBarrierBlockOff className="text-[60px] mb-4" />,
      },
      2: {
        title: "Prevent Accidents",
        description:
          "Guarantee everyone comprehends safety protocols and hazard warnings in their native language, minimizing misunderstandings and preventing accidents.",
        icon: <TbShieldCheck className="text-[60px] mb-4" />,
      },
      3: {
        title: "Boost Productivity",
        description:
          "Streamline communication and eliminate time wasted on clarifications, allowing your team to work efficiently and complete projects on time and within budget.",
        icon: <BiSolidTimer className="text-[60px] mb-4" />,
      },
      4: {
        title: "Foster Inclusivity",
        description:
          "Create a more inclusive work environment where everyone feels valued and understood, leading to increased job satisfaction and higher morale.",
        icon: <RiTeamFill className="text-[60px] mb-4" />,
      },
    },
    features: {
      1: {
        id: "translation",
        image: (
          <img
            src={translation}
            alt="real time translation"
            className="w-[100%]"
          />
        ),
        keywords: ["Zero Confusion", "Boost Productivity"],
        title: "REAL TIME TRANSLATION",
        description:
          "Communication automatically get translated in real time to the language of the recipient. This ensures that everyone is on the same page and that no information is lost in translation.",
      },
      2: {
        id: "multiformat",
        image: (
          <img
            src={communication}
            alt="schedule management"
            className="w-[80%]"
          />
        ),

        keywords: ["Zero Confusion", "Foster Inclusivity", "Prevent Accidents"],
        title: "MULTIPLE COMMUNICATION CHANNELS",
        description:
          "Doesn't matter if it is 1-1 communication, a company-wide announcement, or a group chat, Flowlly can handle it all. ",
      },
    },
    how_it_works: {
      image: <img src={multilingual} alt="how it works" className="w-[90%]" />,
      steps: {
        1: {
          step: "1",
          description:
            "Users create their profile including prefered language for communication.",
        },
        2: {
          step: "2",
          description: "Sender sends messages in their prefered language",
        },
        3: {
          step: "3",
          description: "Flowlly translates messages.",
        },
        4: {
          step: "4",
          description:
            "Receipient receives messages in their prefered language.",
        },
      },
    },
  },
  meetings_landing_page: {
    hero: {
      title: "Meeting Minutes, Made Easy",
      subtitle:
        "The only meetings tool built for the construction industry that captures every detail and writes your meeting minutes, so you don't have to.",
    },
    subsection: {
      text_black: "End the Post-Meeting Scramble",
      text_yellow:
        "writes minutes, extracts and assigns tasks based on meeting details, and follows up with task owners to ensure everything gets done.",
    },
    benefits: {
      1: {
        title: "In person or online",
        description:
          "Whether your meetings are held in person or online, Flowlly seamlessly captures and processes all the information and handles your meeting documentation with ease.",
        icon: <GiTalk className="text-[60px] mb-4" />,
      },
      2: {
        title: "Save Time and Reduce Manual Work",
        description:
          "Eliminate the tedious task of manually writing meeting minutes. Flowlly automatically generates detailed minutes, allowing you to focus on more critical aspects of the project.",
        icon: <BiSolidTimer className="text-[60px] mb-4" />,
      },
      3: {
        title: "Increase Accuracy",
        description:
          "Human note-takers can get distracted, miss details, or fail to capture key points. Flowlly listens carefully, capturing every detail of your meetings with precision, ensuring nothing is overlooked or forgotten.",
        icon: <TbShieldCheck className="text-[60px] mb-4" />,
      },
    },
    steps: {
      1: {
        title: "Click Record",
        description:
          "When your meeting starts, just click 'Record,' and Flowlly will capture all the information. It’s that easy.",
        image: <img src={click_record} alt="record" className="w-[100%]" />,
      },
      2: {
        title: "Review Minutes",
        description:
          "Flowlly automatically generates meeting minutes as soon as the meeting is over. Just review them and make sure it's all there. You can always listen to the recording again.",
        image: <img src={minutes} alt="minutes" className="w-[100%]" />,
      },
      3: {
        title: "Review Tasks",
        description:
          "Along with the minutes, Flowlly also generates a list of tasks that need to be completed based on the meeting, assigns owners, and ads them to the task list.",
        image: <img src={tasks_review} alt="tasks" className="w-[100%]" />,
      },
      4: {
        title: "Share Minutes and Tasks",
        description:
          "Share your minutes and tasks with your team by email. And, if you prefer, you can also send them to Procore and Autodesk.",
        image: <img src={share} alt="share" className="w-[100%]" />,
      },
      5: {
        title: "Tasks Follow Up",
        description:
          "Flowlly automatically tracks the progress of tasks with their owners and keeps your task list up to date.",
        image: <img src={updates} alt="follow up" className="w-[100%]" />,
      },
    },
    features: {
      1: {
        id: "every-detail",
        image: (
          <img
            src={details}
            alt="automated daily reports"
            className="w-[90%]"
          />
        ),
        mobileImage: <img src={mobileDetails} alt="automated daily reports" />,
        keywords: ["Time Savings", "Complete Documentation"],
        title: "NEVER MISS A DETAIL, EVEN IF YOU GET DISTRACTED ",
        description:
          "Flowlly you can capture every detail of your meetings for you. Don't worry if you miss something, Flowlly has the audio recording, transcrip, and minutes so you can focus on the discussion.",
      },
      2: {
        id: "any-location",
        image: (
          <img
            src={record_gif}
            alt="automated daily reports"
            className="w-[100%]"
          />
        ),
        mobileImage: (
          <img
            src={record_gif}
            alt="automated daily reports"
            className="w-[100%]"
          />
        ),
        keywords: ["Time Savings", "Complete Documentation"],
        title: "IN-PERSON OR ONLINE, CAPTURE EVERYTHING",
        description:
          "Whether your meetings are held in person or online, Flowlly seamlessly captures processes all the information meeting documentation with ease.",
      },
      3: {
        id: "task-management",
        image: (
          <img src={tasks_gif} alt="schedule management" className="w-[100%]" />
        ),
        mobileImage: (
          <img src={tasks_gif} alt="schedule management" className="w-[100%]" />
        ),
        keywords: ["Real-time Visibility", "Improved Communication"],
        title: "NO MORE TASKS MANAGEMENT",
        description:
          "Flowlly automatically extracts tasks, assigns them, adds them to your task list, and even follows up. Yes, it really does all that!.",
      },
      4: {
        id: "output-control",
        image: <img src={lastword_gif} alt="reporting" className="w-[100%]" />,
        mobileImage: (
          <img src={lastword_gif} alt="reporting" className="w-[100%]" />
        ),
        keywords: ["Improved Communication", "Complete Documentation"],
        title: "YOU ALWAYS HAVE THE LAST WORD",
        description:
          "Flowlly will automatically generate minutes and tasks for you, but, you can always edit them of anything is missing. Like a real partenrship.",
      },
      5: {
        id: "integrations",
        image: (
          <img src={integrations_gif} alt="reporting" className="w-[100%]" />
        ),
        mobileImage: (
          <img src={integrations_gif} alt="reporting" className="w-[100%]" />
        ),
        keywords: ["Improved Communication", "Complete Documentation"],
        title: "IT PLAYS NICELY WITH YOUR OTHER TOOLS",
        description:
          "Keeping all your project info in one place is key. With just one click, you can easily send your minutes and tasks over to Procore and Autodesk.",
      },
    },
    how_it_works: {
      image: <img src={howitworks} alt="how it works" className="w-[90%]" />,
      steps: {
        1: {
          step: "1",
          description:
            "Flowlly connects to your system of record or scheduling software to retrieve the schedule and directory.",
        },
        2: {
          step: "2",
          description:
            "Users provide information via WhatsApp, SMS, Teams, or email in the form of voice notes, videos, texts, and images.",
        },
        3: {
          step: "3",
          description:
            "If needed, Flowlly requests additional information from the users.",
        },
        4: {
          step: "4",
          description:
            "  Flowlly automatically processes the information and generates reports and schedule impact analysis for review and approval.",
        },
        5: {
          step: "5",
          description:
            "Main user reviews and approves reports and schedule impact.",
        },
        6: {
          step: "6",
          description:
            "   Flowlly syncs reports and schedule updates to systems of record and/or scheduling software.",
        },
      },
    },
    testimonials: {
      1: {
        quote:
          "Our team saved 10 hours per week on admin tasks. Now they spend that time on solving project issues.",
        by: "Project Manager, Vancouver, Canada",
      },
      2: {
        quote:
          "Flowlly has helped us have all of our CYA's documents ready. It has already saved us from a few missunderstandings.",
        by: "Construction Director, Toronto, Canada",
      },
      3: {
        quote:
          "No one really likes doing the meeting minutes and follow ups. Flowlly has been a life changer for us, we can be more engaged and productive in the meeting without worring about taking notes.",
        by: "Project Coordinator, Toronto, Canada",
      },
    },
  },
};

export default content;
