import React, { useState } from "react";

import { createClient } from "@supabase/supabase-js";
import { BsWhatsapp } from "react-icons/bs";
import { MdOutlineSms, MdOutlineEmail } from "react-icons/md";

import SubmittalHeader from "./SubmittalHeader";
import SubmittalForm from "./SubmittalForm";
import hero2 from "../../img/hero-v2.png";
import content from "../../data/content";

// const supabaseUrl = process.env.REACT_APP_SB_URL;
// const supabaseKey = process.env.REACT_APP_SB_KEY;
// const supabase = createClient(supabaseUrl, supabaseKey);

const SubmittalMobile = ({ scroll, width }) => {
  const pageName = "submittal_page";
  const { features } = content[pageName];
  const { how_it_works } = content[pageName];
  const { benefits } = content[pageName];
  return (
    <div className="main bg-white text-black flex flex-col px-1">
      <SubmittalHeader width={width} scroll={scroll} />
      <div className="flex flex-col  justify-center w-[100%]  px-5 items-center mt-[120px] text-center">
        <span className="text-[40px] leading-none font-black">
          {content[pageName].hero.title}
        </span>
        <br />

        <span className="mt-2 text-2xl">{content[pageName].hero.subtitle}</span>
        <button
          className="border-2 border-black p-2 mt-4 font-bold text-lg shadow-[5px_5px_0px_0px_#1a202c] hover:bg-amber-400"
          onClick={() => scroll("footer")}
        >
          Get Started
        </button>
      </div>
      <div className="flex justify-center">
        <img src={hero2} alt="hero" className="mt-[40px] w-[90%]" />
      </div>
      <div className="flex items-center flex-col px-2">
        <div className="bg-white text-black flex flex-col font-black w-[100%] text-center px-2 items-center rounded-xl py-6">
          <span className="text-[28px] leading-10">
            {content[pageName].subsection.text_black}​{" "}
            <span className="text-amber-400">
              {content[pageName].subsection.text_yellow}
            </span>
            <div>
              <p className="text-2xl mt-10">Works with</p>
              <div className="flex flex-row mt-2 justify-between px-10">
                <div className="flex flex-col items-center mr-8 w-[50px]">
                  <BsWhatsapp className="text-3xl mb-1" />
                  <span className="text-sm">Whatsapp</span>
                </div>
                <div className="flex flex-col items-center mr-8 w-[50px]">
                  <MdOutlineSms className="text-3xl mb-1" />
                  <span className="text-sm">SMS</span>
                </div>
                <div className="flex flex-col items-center w-[50px]">
                  <MdOutlineEmail className="text-3xl mb-1" />
                  <span className="text-sm">Email</span>
                </div>
              </div>
            </div>
          </span>
        </div>
        <div
          className="flex flex-row overflow-x-auto mb-[20px] px-2 py-10 w-[90%] no-scrollbar section"
          id="benefits-mobile"
        >
          {Object.values(benefits).map((benefit, index) => (
            <div
              key={index}
              className="min-w-[250px] border-2 border-black p-4 h-[350px] shadow-[5px_5px_0px_0px_#1a202c] mx-4"
            >
              {benefit.icon}
              <h1 className="font-bold text-[22px] mb-2">{benefit.title}</h1>
              <p className="text-md">{benefit.description}</p>
            </div>
          ))}
        </div>
      </div>
      <div className="border-t border-white rounded-xl bg-orange-100 text-black mt-6 w-[100%] items-center flex flex-col mb-12 py-10">
        {Object.values(features).map((feature, index) => (
          <div
            key={index}
            className=" flex justify-center flex-col text-center p-4 section"
            id={feature.id}
          >
            <span className="text-[24px] font-black">{feature.title}</span>
            <p className="mt-6">{feature.description}</p>

            <div className="flex flex-row justify-center mt-10">
              {feature.image}
            </div>

            {index < Object.values(features).length - 1 && (
              <div className="mt-12 min-w-[80%] min-h-[7px] bg-white border-2 border-black"></div>
            )}
          </div>
        ))}
      </div>
      <div className="my-8">
        <div className="flex flex-col ">
          <h1
            className="text-4xl font-bold text-center mb-4 section"
            id="how-it-works-mobile"
          >
            How it works
          </h1>
          <div className="flex justify-center">{how_it_works.image}</div>
        </div>
        <div className="flex flex-row overflow-x-auto no-scrollbar w-[90%] px-6 mt-6 mb-12">
          {Object.values(how_it_works.steps).map((step, index) => (
            <div key={index} className="min-w-[300px]">
              <div className="flex p-1 border-2 border-black w-[40px] h-[40px] justify-center shadow-[5px_5px_0px_0px_#1a202c] mb-2">
                <h1 className="text-2xl font-bold">{step.step}</h1>
              </div>
              <p className="text-lg px-4 py-2">{step.description}</p>
            </div>
          ))}
        </div>
      </div>
      <SubmittalForm width={width} />
    </div>
  );
};

export default SubmittalMobile;
