import React, { createContext, useContext } from "react";
import { createClient } from "@supabase/supabase-js";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";

export function generateSlug(text) {
  return text
    .toString()
    .toLowerCase()
    .replace(/\s+/g, "-") // Replace spaces with -
    .replace(/[^\w-]+/g, "") // Remove all non-word chars
    .replace(/--+/g, "-") // Replace multiple - with single -
    .trim(); // Trim the leading/trailing -
}

const supabaseUrl = process.env.REACT_APP_SB_URL;
const supabaseKey = process.env.REACT_APP_SB_KEY;

export const supabase = createClient(supabaseUrl, supabaseKey);

// export const scrollToSection = (sectionId) => {
//   const destinationSection = document.getElementById(sectionId);

//   if (destinationSection) {
//     destinationSection.scrollIntoView({ behavior: "smooth" });
//   } else {
//     console.error(`Section with ID "${sectionId}" not found.`);
//   }
// };

export const scrollToSection = (sectionId, offset = 290) => {
  // Default offset
  const destinationSection = document.getElementById(sectionId);

  if (destinationSection) {
    const yOffset =
      destinationSection.getBoundingClientRect().top +
      window.pageYOffset -
      offset;
    window.scrollTo({ top: yOffset, behavior: "smooth" });
  } else {
    console.error(`Section with ID "${sectionId}" not found.`);
  }
};

// const scrollToSection = () => {
//   const { pathname, hash } = useLocation();

//   useEffect(() => {
//     if (hash && pathname === "/") {
//       const section = document.querySelector(hash);
//       if (section) {
//         // Timeout ensures the element is present in the DOM when coming from another page
//         setTimeout(() => {
//           section.scrollIntoView({ behavior: "smooth" });
//         }, 100);
//       }
//     }
//   }, [pathname, hash]);

//   return null;
// };

// export default scrollToSection;
