import React, { useState, useRef } from "react";
import { FaCirclePlay } from "react-icons/fa6";

function VideoPlayer({ showVideo, onToggle }) {
  // Add posterSrc prop

  // const [showVideo, setShowVideo] = useState(false);
  const videoRef = useRef(null);
  const videoSrc = "/time_suck.mp4";

  // const handleImageClick = () => {
  //   setShowVideo(true);
  // };

  return (
    <div>
      {/* <div className="relative w-[100%] 2xl:w-[90%]">
        <img
          src={posterSrc}
          alt="Click to play video"
          className="cursor-pointer"
        />
        <div
          className="absolute top-4 right-6 flex items-center bg-white border-2 border-black font-bold shadow-[5px_5px_0px_0px_#1a202c] justify-center  text-black hover:bg-amber-400 hover:opacity-100 transition-opacity duration-300 p-2 cursor-pointer" // Changed to position at top right
          onClick={handleImageClick}
        >
          Play Video
        </div>
      </div> */}

      {showVideo && (
        <div
          className="fixed top-0 left-0 w-full h-full flex items-center justify-center bg-black bg-opacity-75 z-50"
          // onClick={() => setShowVideo(false)} // Close on clicking outside
          onClick={onToggle} // Call the onToggle prop
        >
          <div
            className="bg-white p-4 rounded-lg relative w-[80%]"
            onClick={(e) => e.stopPropagation()}
          >
            <video
              ref={videoRef}
              width="100%"
              src={videoSrc}
              controls
              autoPlay // Start playing when popup opens
              onEnded={onToggle} // Close when video ends
            />
            <button
              onClick={onToggle}
              className="absolute top-2 right-2 text-gray-500 hover:text-gray-700"
            >
              {/* Add close icon here */}
            </button>
          </div>
        </div>
      )}
    </div>
  );
}

export default VideoPlayer;
